@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

// General color
$color-primary: #ECB801;
$color-on-primary : #FFFFFF;
$color-on-background : #000000;

$background-color : #FFFFFF;
$icon-size : 1.5rem;
$alpha : 0.25;
$sm-device : 37.5rem; //600px
$me-device : 45rem; //720px
$lg-device : 60rem; //960px

// Button
$btn-color-primary : #ECB801;
$btn-text-color : #FFFFFF;
$btn-color-secondary : #D1E9FF;

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-weight: 400;
    font-family: 'inter', system-ui, sans-serif;
}
body{
    font-family: 'inter', system-ui, sans-serif;
    background-color: $background-color;
}

html{
    @media only screen and (min-width : $me-device){
        font-size: 115%;
    }
    @media only screen and (min-width : $lg-device){
        font-size: 130%;
    }
}

@font-face {
    font-family: 'Javacom';
    src: url('../../../public/fonts/Javacom.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'inter';
    src: url('../../../public/fonts/Inter-VariableFont_slnt\,wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}