@keyframes fade {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@keyframes rotate{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}

@keyframes blink{
    0%{
        opacity: 1;
    }
    50%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@keyframes swing{
    0%{
        transform: rotate(-1deg);
    }
    50%{
        transform: rotate(2deg);
    }
    100%{
        transform: rotate(-1deg);
    }
}
@keyframes swingreverse{
    0%{
        transform: rotate(1deg);
    }
    50%{
        transform: rotate(-2deg);
    }
    100%{
        transform: rotate(1deg);
    }
}

