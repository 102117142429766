//todo Pion
.pion {
    &-selected {
      box-shadow: 0px 0 4px 2px rgba(201, 108, 0, 1);
    }
    &-winsAnimation {
      box-shadow: 0px 0 4px 4px rgba(201, 108, 0, 1);
      transition: box-shadow 2s linear, transform 2s linear;
    }
    transition: transform 0.2s linear;
  }
.modal {
    transform: scale(0);
    visibility: hidden;
    display: block;
    
    &-animation {
    transform: scale(1);
    visibility: visible;
    display: block;
    transition: transform 1s 2s linear;
    }
    &-show{
      transform: scale(1);
      visibility: visible;
      display: block;
    }
  }
  